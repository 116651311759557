import axios from "axios";

// Get all price options
const priceOptions = document.querySelectorAll(".price-option");

// Listen for changes on each select
Array.from(priceOptions).forEach((priceOption) => {
	const select = priceOption.querySelector("select");
	const button = priceOption.querySelector("hedge-button");
	const link = priceOption.querySelector("a");

	if (select) {
		select.addEventListener("change", (event) => {
			const select = event.currentTarget;
			const selectedOption = select.options[select.selectedIndex];

			const { price, url } = selectedOption.dataset;

			link.href = url;
			button.innerHTML = price;
		});
	}
});

window.addEventListener("DOMContentLoaded", () => {
	const downloadModal = document.getElementById("downloadModal");
	const downloadForm = document.getElementById("downloadForm");
	const submitButtons = downloadForm.querySelectorAll(
		'hedge-button[name="selectedOS"]'
	);

	const handleSubmit = (selectedOS) => {
		const data = {
			source: "",
			tag: "",
			selectedOS,
		};

		const formData = new FormData(downloadForm);
		for (const [key, value] of formData.entries()) {
			data[key] = value;
		}

		const options = {
			url: downloadForm.action,
			method: downloadForm.method,
			withCredentials: true,
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				"X-CSRF-TOKEN": data.csrf,
			},
			data: JSON.stringify(data),
		};

		return axios(options);
	};

	downloadForm.addEventListener("submit", (event) => {
		event.preventDefault();
	});

	Array.from(submitButtons).forEach((button) => {
		button.addEventListener("click", async () => {
			const windowReference = window.open();

			const selectedOS = button.value;
			const downloadUrl = downloadForm.dataset[selectedOS];

			try {
				await handleSubmit(selectedOS);
			} catch (error) {
			} finally {
				windowReference.location = downloadUrl;
				downloadModal.hide();
			}
		});
	});
});

// Replace refs
window.addEventListener("DOMContentLoaded", () => {
	const anchors = document.querySelectorAll('a[href*="referring_domain={ref}"], a[href*="ref={ref}"]');
	const inputs = document.querySelectorAll('input[value="{ref}"]');

	Array.from(anchors).forEach((anchor) => {
		const { query: locationQuery } = url.parse(document.location.href, true);
		const { query: anchorQuery, search, ...anchorInfo } = url.parse(anchor.href, true);

		if ("ref" in anchorQuery) {
			anchorQuery["ref"] = locationQuery.ref;
		}

		if ("referring_domain" in anchorQuery) {
			anchorQuery["referring_domain"] = locationQuery.ref;
		}

		anchor.href = url.format({
			...anchorInfo,
			query: anchorQuery,
		});
	});

	Array.from(inputs).forEach((input) => {
		const { query: locationQuery } = url.parse(document.location.href, true);
		input.value = locationQuery.ref;
	});
});
